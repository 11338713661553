<template>
  <div class="box-container">
    <v-row>
      <v-col>
        <div class="d-flex align-center mb-5">
          <v-avatar
            tile
            size="25"
            class="me-3"
          >
            <img
              src="@/assets/images/icons/food_primary.svg"
              alt=""
            >
          </v-avatar>
          <h2 class="mb-0">
            {{ $t("Gestion des promotions") }}
          </h2>
        </div>
      </v-col>
      <v-col cols="auto">
        <v-row justify-lg="end">
          <!--          <v-col
            cols="12"
            md="6"
            lg="6"
          >
            <v-text-field
              v-model="filters.search"
              outlined
              dense
              hide-details
              :label="$t('Recherche...')"
            />
          </v-col>-->
          <v-col
            cols="12"
          >
            <v-btn
              to="/promotionform"
              color="primary"
            >
              {{ $t("Ajouter un nouveau") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selected"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :headers="headers"
      :loading-text="$t('Chargement...')"
      :loading="loading"
      :elevation="0"
      item-key="id"
      show-select
      :options.sync="pagination"
      :server-items-length="total"
      dense
      :footer-props="{ showFirstLastPage, showCurrentPage }"
      :multi-sort="false"
    >
      <template v-slot:item.status="{item}">
        <v-chip
          v-if="item.status === 1"
          dark
          x-small
          color="success"
        >
          {{ $t("Actif") }}
        </v-chip>
        <v-chip
          v-else-if="item.status === 0 || !item.status"
          x-small
          color="warning"
        >
          {{ $t("Inactif") }}
        </v-chip>
      </template>
      <template v-slot:item.created_at="{item}">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:item.action="{item}">
        <v-btn
          text
          color="red"
          small
          :disabled="item.status === 1"
          @click="confirmPromotion(item)"
        >
          <v-icon class="glyphicon glyphicon-remove">
            mdi-minus
          </v-icon> {{ $t("Supprimer") }}
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="confirm_dialog"
      width="400"
    >
      <v-card v-if="item.id">
        <v-card-title>
          {{ $t("Est vous sur de vouloir supprimer?") }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            small
            outlined
            depressed
            @click.stop="confirm_dialog = false"
          >
            {{ $t("Fermer") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            depressed
            :loading="loadingDelete"
            @click.stop="deletePromotion"
          >
            {{ $t("Continue") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>
<script>

  import * as url from 'url'
  import CategoryPromotionForm from '../../components/shops/CategoryPromotionForm.vue'
  import { mapState } from 'vuex'
  import i18n from '../../i18n'

  export default {
    components: {

    },
    props: {
    },
    data () {
      return {
        selected: [],
        isSidebar: false,
        dateMenu: false,
        activePicker: null,
        promotionDialog: null,
        filters: {
          status: null,
          search: '',
          date: null, /* [new Date(Date.now()).toISOString().substr(0, 10), new Date(Date.now()).toISOString().substr(0, 10)] */
        },
        page: 0,
        itemsPerPage: 5,
        pagination: { itemsPerPage: 5, page: 1 },
        item: {},
        items: [],
        total: 0,
        loading: false,
        timeout: null,
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        search: '',
        url: '/shop-promotion',
        confirm_dialog: false,
        loadingDelete: false,
        snackbar: { active: false, text: '', color: 'info' },

        baseurl: '/',
        payment_type: {},
        price: '',
        password: '',
        condition_legal: false,
        currency: 'EUR',
        CardType: 'CB_VISA_MASTERCARD',
        payment_method: 'wallet',
        newprice: false,
        paymentform: false,
        costlable: i18n.t('cout de la promotion'),
        promotion: {
          datestart: null,
          promotion_type: {},
          promotion_value: {},
        },
        promotions: [],
        promotion_types: [],
        promotion_values: [],
        promotion_value_items: [],
        categoryselected: [],
        headers: [
          { text: 'N°', value: 'id' },
          { text: 'Type de promotion', value: 'promotion_type.name' },
          { text: 'Durée', value: 'promotion_value.duration' },
          { text: 'Date Début', value: 'datestart' },
          { text: 'Date Fin', value: 'dateend' },
          { text: 'Cout', value: 'price' },
          { text: 'Etat', value: 'status' },
          { text: 'Crée le', value: 'created_at' },
          { text: 'Action', value: 'action', sortable: false },
        ],

      }
    },
    computed: {
      ...mapState({
        user: 'user',
        login: 'login',
        displayNotification: 'display_notification',
      }),
      pages () {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0

        return Math.ceil(this.total / this.pagination.rowsPerPage)
      },
      params () {
        return {
          ...this.pagination, /*
          query: this.search, */
        }
      },
    },
    watch: {
      params: {
        handler () {
          this.items = []
          this.total = 0
          this.page = 0
          this.getData().then(data => {
            this.items = data.items
            this.total = data.total
            if (this.page + 1 <= this.numberOfPages) this.page += 1
          })
        },
        deep: true,
      },
    },
    mounted () {
      this.url = this.$route.path
      this.getData().then(data => {
        this.items = data.items
        this.total = data.total
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      })
    },
    created () {

    },
    destroyed () {
    },
    methods: {
      season (val) {
        return this.range[val]
      },
      getData () {
        this.loading = true
        return new Promise((resolve, reject) => {
          console.log(this.pagination)
          var _this = this
          /* if(this.pagination.sortBy)
                               for(var i=0;i<this.pagination.sortBy.length;i++){

                                   orderBy.push({
                                       column:this.pagination.sortBy[i],
                                       direction:this.pagination.sortDesc[i]?"DESC":"ASC"
                                   });
                               } */

          // get by search keyword
          // eslint-disable-next-line no-undef
          axios.get(this.url, {
            params: {
              term: this.search,
              orderBy: this.pagination.sortBy ? this.pagination.sortBy : 'id',
              direction: (this.pagination.sortDesc) ? (this.pagination.sortDesc[0] ? 'ASC' : 'DESC') : 'ASC',
              page: this.pagination.page,
              per_page: this.pagination.itemsPerPage,
            },
          })
            .then(res => {
              const items = res.data.data
              const total = res.data.total
              this.loading = false
              resolve({
                items,
                total,
              })
            })
            .catch(err => console.log(err))
            // eslint-disable-next-line no-return-assign
            .finally(() => _this.loading = false)
        })
      },
      confirmPromotion (item) {
        this.loadingDelete = false
        this.item = item
        this.confirm_dialog = true
      },
      deletePromotion () {
        this.loadingDelete = true
        axios.delete(`/promotions/${this.item.id}`).then((response) => {
          this.loadingDelete = false
          if (response.data && response.data.error) {
            this.snackbar.text = response.data.message || 'Erreur'
            this.snackbar.active = true
          } else {
            this.items.splice(this.items.findIndex(e => e.id === this.item.id), 1)
          }
        }).then(() => {
          this.loadingDelete = false
          this.confirm_dialog = false
        })
      },
    },
  }
</script>
