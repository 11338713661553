<template>
  <div class="chute chute-center">
    <!-- AllCP Info -->
    <div class="allcp-panels fade-onload">
      <div
        id="spy3"
        class="panel"
      >
        <div class="panel-heading">
          <div class="topbar-left">
            <ol class="breadcrumb">
              <li class="breadcrumb-link">
                <h5 class="float-left">
                  Menu
                </h5>
              </li>
            </ol>
          </div>
        </div>

        <div class="panel-body pn mt20">
          <div class="row">
            <div class="col-md-7">
              <div class="card mb-3">
                <div
                  hidden
                  class="card-header"
                >
                  <input
                    v-model="search"
                    placeholder="Rechercher une categorie ..."
                    class="form-control gui-input"
                    @keyup="findcategory($event)"
                  >
                  <button
                    style="position: absolute; right: 20px; top: 7px"
                    type="button"
                    class="btn"
                    @click="cancelsearch()"
                  >
                    <i class="fa fa-close " />
                  </button>

                  <hr>
                </div>
                <div class="card-body">
                  <ul
                    id="sortable"
                    class=" menu-web menu-bg-white categorylist"
                  >
                    <li
                      v-for="(category, $index) in categories"
                      :key="category.id"
                    >
                      <div class="dd-handle dd-primary">
                        {{ category.name }}
                        <span class="float-right fs11 fw600">
                          <a
                            type="button"
                            class="btn btn-link"
                            @click="create(category)"
                          >
                            <i class="fa fa-copy" /></a>

                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-5">
              <div class="card border-primary mb-3">
                <div class="card-header bg-primary text-white">
                  Catégorie selectionné
                </div>
                <div class="card-body">
                  <ul
                    id="sortable"
                    class=" menu-web menu-bg-white categorylist"
                  >
                    <li
                      v-for="(category, $index) in categoryselected"
                      :key="category.id"
                    >
                      <div class="dd-handle dd-primary">
                        {{ category.name }}
                        <span class="pull-right fs11 fw600">
                          <button
                            type="button"
                            class="btn btn-danger"
                            @click="_delete(category, $index)"
                          >
                            <i class="fa fa-close" /></button>

                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: ['promotion', 'user', 'product'],

    data () {
      return {
        category: {},
        el: null,
        categoriestring: '',
        search: '',
        resultdatas: [],
        categories: [],
        categorytree: [],
        categoryselected: [],
      }
    },
    mounted () {
      this.init()
    },
    methods: {
      cancelsearch () {
        this.search = ''
        this.resultdatas = []
        this.init()
      },
      findcategory (e) {
        if (e.keyCode === 13) { // || product.id
          return
        }

        if (this.search.length >= 3) {
          // $("#box-loader").show();
          var self = this
          if (this.resultdatas.length) {
            this.categories = this.filterrow(this.search, this.resultdatas)
            return
          }
          // else
          axios.get(window.axios.defaults.baseURL + '/categories-find/' + devups.escapeHtml(this.search))//
            .then((response) => {
              console.log(response)
              this.categorytree = []
              this.resultdatas = response.data
              this.categories = response.data
            }).catch(function (error) {
              console.log(error)
            })
        } else {
          $('#productselected').html('')
          this.categories = []
          this.resultdatas = []
        }
      },

      filterrow (value, dataarray) {
        var filter; var filtered = []; var tr; var td; var i; var data

        console.log(dataarray)
        filter = value.toUpperCase()

        for (i = 0; i < dataarray.length; i++) {
          data = dataarray[i]
          if (data.name.toUpperCase().indexOf(filter) > -1) {
            filtered.push(data)
          }
        }
        return filtered
      },

      edit (el) {
        // this.el =el;
        this.category = el
      },
      changestatus (el, status) {
        el.status = status
        console.log(el)
        axios.get(window.axios.defaults.baseURL + '/admin/categories/changestatus/' + el.id + '/' + status)
          .then(function (response) {
            console.log(response)
          }).catch(function (error) {
            console.log(error)
          })
      },
      _delete (el, index) {
        // this.el =el;

        if (!confirm('Voulez-vous Supprimer?')) return false
        this.categoryselected.splice(index, 1)

        this.$emit('categoryselected', this.categoryselected)
        // axios.get(window.axios.defaults.baseURL + "/categories-delete/" + el.id)
        //     .then(function (response) {
        //         console.log(response);
        //         this.categories.splice(index, 1);
        //     }).catch(function (error) {
        //     console.log(error);
        // });
      },
      create (el) {
        let cat = this.category

        // var datajson = JSON.stringify(this.category);
        for (cat of this.categoryselected) {
          if (cat.id === el.id) {
            alert('category already selected')
            return
          }
        }
        this.categoryselected.push(el)

        this.$emit('categoryselected', this.categoryselected)
      },
      save () {
        var els = $('.sortableLists').find('.li')

        const cc = builcollection(els)
        this.categories = JSON.parse(cc)
        console.log(this.categories)
        this.categoriestring = cc

        axios.post(window.axios.defaults.baseURL + 'categories.store', { data: cc })
          .then(function (response) {
            console.log(response)
          }).catch(function (error) {
            console.log(error)
          })
      },
      init () {
        console.log(window.axios.defaults.baseURL + '/categories/getdata')
        this.category = {}
        this.categorytree = []
        axios.get(window.axios.defaults.baseURL + '/categories/getdata')
          .then((response) => {
            console.log(response)
            this.categories = response.data
          }).catch(function (error) {
            console.log(error)
          })
      },

    },
  }

</script>
